import React from "react";

export const ProductFinderBox = () => (
  <section className="contentBox maxContainer">
    <div className="container-fluid">
      <main className="contentBox__content">
        <div className="contentBox__item imageTeaser2 col2">
          <p className="imageTeaser2__title">People light our future</p>

          <a href="{}" className="imageTeaser2__link">
            <img className="imageTeaser2__image" title="Connected Lighting" alt="Connected Lighting" src="../fileadmin/_processed_/6/f/csm_teaser01_3d351ede60.jpg" width="574" height="334" />

            <p className="imageTeaser2__subTitle">
              Connected
              <br /> Lighting
            </p>
          </a>
        </div>

        <div className="contentBox__item imageTeaser2 col2">
          <p className="imageTeaser2__title">People light our designs</p>

          <a href="{}" className="imageTeaser2__link">
            <img className="imageTeaser2__image" title="Product finder" alt="Product finder" src="../fileadmin/_processed_/1/a/csm_home_productfinder_teaser_40618cd356.jpg" width="574" height="334" />

            <p className="imageTeaser2__subTitle">Product finder</p>
          </a>
        </div>
      </main>
    </div>
  </section>
);
