import React from "react";

export const VideoPlayerBox = () => (
  <div id="c1929" className="defaultContainer">
    <div className="article-box  ce-textpic ce-center ce-above">
      <div className="ce-gallery image-holder " data-ce-columns="1" data-ce-images="1">
        <div className="ce-outer">
          <div className="ce-inner">
            <div className="ce-row">
              <div className="ce-column">
                <figure className="video">
                  <div className="video-embed">
                    <div className="embed-responsive embed-responsive-16by9">
                      <video width="1200" controls no-cookie className="video-embed-item">
                        <source src="../fileadmin/user_upload/pages/home/Regent_Joker_OfficeLight_Ende_Echtbild_Final_Low.mp4" type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-box ce-bodytext">
        <p>
          <a href="{}" target="_blank" className="btn btn-default">
            Highlights
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a href="{}" target="_self" className="btn btn-default">
            Go to product
          </a>
        </p>
      </div>
    </div>
  </div>
);
