import "../../../assets/fileadmin/main/css/index.css";
import { ShortCutSidebar } from "../../../components/Website/ShortCutSidebar/ShortCutSideBar";
import { useRightSideBar } from "../../../components/Website/ShortCutSidebar/_hook";

import "./siteHome.css";
import Newsletter from "../../../components/Website/NewsLetter";
import Jobs from "../../../components/Website/Jobs";
import SearchBoxOverlay from "../../../components/Website/SearchBoxOverlay/SearchBoxOverlay";
import { HeaderContentBox } from "../../../components/Website/HeaderContentBox/HeaderContentBox";
import { VideoPlayerBox } from "../../../components/Website/VideoPlayerBox/VideoPlayerBox";
import { AllProjectBox } from "../../../components/Website/AllProjectBox/AllProjectBox";
import { ProductFinderBox } from "../../../components/Website/ProductFinder/ProductFinderBox";
import { RichTextBox } from "../../../components/Website/RichTextBox/RichTextBox";
import OwlCarouselSliderHome from "../../../components/Website/Owl-Carousel/OwlCarouselSliderHome";

function SiteHome() {
  const y = useRightSideBar();

  return (
    <section id="wrapper" className="wrapper">
      <SearchBoxOverlay />
      <main role="main" id="main">
        <div className="container-fluid">
          <div className="column-box"></div>

          {/* <!--TYPO3SEARCH_begin--> */}
          <section className="fdSlider maxContainer count12">
            <div className="container-fluid">
              <OwlCarouselSliderHome />
            </div>
          </section>
          <HeaderContentBox />
          <VideoPlayerBox />
          <AllProjectBox />
          <ProductFinderBox />
          <RichTextBox />
          <Jobs />
          <Newsletter />
          {/* <!--TYPO3SEARCH_end--> */}
        </div>
      </main>
      <ShortCutSidebar y={y} />
      <div className="compareWishlist" data-count=""></div>
    </section>
  );
}

export default SiteHome;
