import { IAction } from "../../configs/types";
import { EScreenFieldNames } from "../../services/screen/screenTypes";
import { EScreenActionTypes } from "./FullScreenAction";

export interface IScreenState {
  [EScreenFieldNames.Screen]?: any | boolean;
}

const initialState: IScreenState = Object.freeze({
  [EScreenFieldNames.Screen]: false,
});

export default function screenReducer(state = initialState, action: IAction<EScreenActionTypes, IScreenState>) {
  switch (action.type) {
    case EScreenActionTypes.Screen:
      return {
        ...state,
        ...(action.payload as IScreenState),
      };
    default:
      return state;
  }
}
