import React, { useCallback, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { getAllSlider } from "../../../services/product-finder/productServices";
import { IMAGE_URL } from "../../../services";
import { useNavigate } from "react-router-dom";

const PATH = "website/slider/";

interface IAPIReponse {
  id: number;
  title: string;
  sub_title: string;
  client: string;
  button_title: string;
  page_link: string;
  image: string;
  enabled: number;
}

const OwlCarouselSliderHome = ({ ProjectBanner }: any) => {
  const [bannerData, setBannerData] = useState([] as IAPIReponse[]);
  const navigate = useNavigate();

  const getAllDataFromServer = useCallback(() => {
    getAllSlider().then((response) => {
      if (Array.isArray(response?.responseData)) {
        setBannerData(response.responseData);
      }
    });
  }, []);

  useEffect(() => {
    getAllDataFromServer();
  }, [getAllDataFromServer]);

  return (
    <div key={bannerData.length} className="fdSlider mb-0">
      <OwlCarousel dots={true} className="owl-theme" loop margin={10} nav items={1}>
        {bannerData.map((obj) => {
          let image = IMAGE_URL + PATH + obj.image;
          return (
            <div className="item" key={image}>
              <div className="slide darken bgBlack">
                <div className="slide__bgImage" style={{ backgroundImage: `url(${image})` }} />
                <div className="slide__textBox align--center">
                  <p className="slide__title">{obj.title}</p>
                  <p
                    className="slide__subtitle"
                    dangerouslySetInnerHTML={{ __html: obj.sub_title }}
                  ></p>
                  <div className="slide__text">
                    <p>{obj.client}</p>
                    <p>
                      <a
                        onClick={() => navigate(obj.page_link)}
                        target="_self"
                        className="btn btn-transparent"
                      >
                        {obj.button_title}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </OwlCarousel>
    </div>
  );
};

export default OwlCarouselSliderHome;
