import React from "react";

export const RichTextBox = () => (
  <section className="contentBox defaultContainer">
    <div className="container-fluid">
      <main className="contentBox__content">
        <div className="contentBox__item richtext  col2">
          <h2 className="richtext__title ">Regent Lighting Bespoke</h2>

          <p className="subheader">You dream it and we will build it</p>
        </div>

        <div className="contentBox__item richtext  col2">
          <p>
            People are at the heart of everything we do. When developing our lighting, the focus is always on our customers. Our portfolio offers an extensive range of high-quality products in
            elegant, modern designs. We have the right lighting for diverse environments and applications and also design bespoke lighting solutions according to your specifications. Working with you,
            we develop tailor-made lighting solutions to suit your unique project. We bring your idea to life and let it shine in the best possible light.
          </p>
          <p>
            <a href="{}" className="btn btn-default">
              Read more
            </a>
          </p>
        </div>

        <div className="contentBox__item imageTeaser col2">
          <img className="imageTeaser__image" title="Bespoke" alt="Bespoke" src="/fileadmin/_processed_/6/3/csm_img04_95d2d8c304.jpg" width="692" height="592" />
        </div>

        <div className="contentBox__item imageTeaser col2">
          <img className="imageTeaser__image" title="Bespoke" alt="Bespoke" src="../fileadmin/_processed_/8/9/csm_img05_77ffa0be71.jpg" width="692" height="592" />
        </div>
      </main>
    </div>
  </section>
);
