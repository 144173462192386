import { IAction } from "../../configs/types";
import { IScreenState } from "./FullScreenReducer";

export enum EScreenActionTypes {
  "Screen" = "screen",
}

export const screenAction = (screen: IScreenState): IAction<EScreenActionTypes, IScreenState> => ({
  type: EScreenActionTypes.Screen,
  payload: screen,
});
