import { IAction } from "../../configs/types";
import { IUserState } from "./userReducer";

export enum EUserActionTypes {
  "SignIn" = "SIGN_IN",
  "SignOut" = "SIGN_OUT",
};

export const userActionSignIn = (userData: IUserState): IAction<EUserActionTypes, IUserState> => ({
  type: EUserActionTypes.SignIn,
  payload: userData
});

export const userActionSignOut = (): IAction<EUserActionTypes> => ({
  type: EUserActionTypes.SignOut,
});