import { combineReducers } from "redux";

import userReducer, { IUserState } from "./user/userReducer";
import screenReducer, { IScreenState } from "./user/FullScreenReducer";

export interface IRootState {
  user: IUserState;
  screen: IScreenState;
}

const rootReducer = combineReducers({
  user: userReducer,
  screen: screenReducer,
});

export default rootReducer;
