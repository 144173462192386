import React from "react";
import { useNavigate } from "react-router-dom";

function Newsletter() {
  const natigate = useNavigate();
  return (
    <section className="blackTeaser maxContainer">
      <div className="container-fluid">
        <div className="blackTeaser__content">
          <header className="blackTeaser__header">
            <h2 className=" blackTeaser__title">Newsletter</h2>
          </header>

          <main className="blackTeaser__text">
            <p>Sign up for our newsletter to find out more about our new products, technologies and digital solutions.</p>
            <p>
              <a onClick={() => natigate("/sign-up-to-newsletter")} className="btn btn-transparent">
                Sign up to newsletter
              </a>
            </p>
          </main>
        </div>
      </div>
    </section>
  );
}

export default Newsletter;
