import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
interface Props {
  y: any;
}
export function ShortCutSidebar({ y }: Props) {
  const [openPhone, setPhone] = useState(false);
  const [message, setMessage] = useState(false);

  useEffect(() => {
    setPhone(false);
    setMessage(false);
  }, [y]);

  return (
    <section className="shortcutSidebar" id="shortcutSidebar_id">
      <ul className="shortcutSidebar__buttons">
        <li className="shortcutSidebar__button phone">
          <Link onClick={() => setPhone(!openPhone)} className="shortcutSidebar__link sidebarOpener" to={""}>
            <span className="shortcutSidebar__icon icon-phone"></span>
          </Link>
          <div className={"shortcutSidebar__detail sidebarDetail detailPhone " + (openPhone ? "opened" : "")}>
            <span>Consultation hotline product news</span>
            <br />
            <a href={"tel:+41 800 990 007"}>{"+41 800 990 007"}</a>
          </div>
        </li>
        <li className="shortcutSidebar__button mail">
          <a className="shortcutSidebar__link" href="contact/index.html">
            <span className="shortcutSidebar__icon icon-envelope"></span>
          </a>
        </li>
        <li className="shortcutSidebar__button location">
          <a className="shortcutSidebar__link" href="locations/index.html">
            <span className="shortcutSidebar__icon icon-location"></span>
          </a>
        </li>
        <li className="shortcutSidebar__button message">
          <Link onClick={() => setMessage(!message)} className="shortcutSidebar__link sidebarOpener" to={""}>
            <span className="shortcutSidebar__icon icon-message"></span>
          </Link>
          <div className={"shortcutSidebar__detail sidebarDetail " + (message ? "opened" : "")}>
            <ul className="social-list">
              <li className="linkedin">
                <a href="https://www.linkedin.com/company/regent-lighting/" target="_blank" rel="noreferrer">
                  <i className="icon-linkedin"></i>
                  <span className="hidden">linkedin</span>
                </a>
              </li>
              <li className="pinterest">
                <a href="https://www.pinterest.de/regent_lighting_global/" target="_blank" rel="noreferrer">
                  <i className="icon-pinterest"></i>
                  <span className="hidden">pinterest</span>
                </a>
              </li>
              <li className="instagram">
                <a href="https://www.instagram.com/regent_lighting/" target="_blank" rel="noreferrer">
                  <i className="icon-instagram"></i>
                  <span className="hidden">instagram</span>
                </a>
              </li>
              <li className="vimeo">
                <a href="https://vimeo.com/regentlighting" target="_blank" rel="noreferrer">
                  <i className="icon-vimeo"></i>
                  <span className="hidden">vimeo</span>
                </a>
              </li>
              <li className="youtube">
                <a href="https://www.youtube.com/channel/UCR-jD-DVtK8FbuSlh9TRSIQ/videos" target="_blank" rel="noreferrer">
                  <i className="icon-youtube"></i>
                  <span className="hidden">youtube</span>
                </a>
              </li>
              <li className="facebook">
                <a href="https://www.facebook.com/RegentLightingGlobal/" target="_blank" rel="noreferrer">
                  <i className="icon-facebook"></i>
                  <span className="hidden">facebook</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </section>
  );
}
