import { useCallback, useEffect, useState } from "react";

export const useRightSideBar = () => {
  const [y, setY] = useState(window.scrollY);

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      let element = document.getElementById("shortcutSidebar_id");
      if (y > window.scrollY) {
        window.scrollY !== 0 && window.scrollY < 400 && element?.classList.remove("active");
      } else if (y < window.scrollY) {
        window.scrollY !== 0 && window.scrollY > 400 && element?.classList.add("active");
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    window.addEventListener("scroll", (e) => handleNavigation(e));

    return () => {
      window.removeEventListener("scroll", (e) => handleNavigation(e));
    };
  }, [handleNavigation, y]);
  return y;
};
