import moment from "moment";
import toastr from "toastr";

import { IFormData, IResponse } from "./types";

export const showResponseMessage = (response: IResponse) => {
  if (response?.error || !response?.message || (response?.statusCode || 0) > 300) return toastr.error(response?.error || response?.message || "Something went wrong.", "Error.");
  toastr.success(response.message, "Success.");
};

export function hexToRgbA(hex: string, opacity = 1) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return "rgba(" + [(+c >> 16) & 255, (+c >> 8) & 255, +c & 255].join(",") + `,${opacity})`;
  }
  return "rgba(255, 255, 255, 1)";
}

export function debounce<F>(func: F, wait = 500, immediate = false) {
  let timeout: ReturnType<typeof setTimeout> | null;

  return function executedFunction(this: typeof debounce) {
    const context = this;
    const args = arguments;

    const later = function () {
      timeout = null;
      if (!immediate) (func as unknown as Function).apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout as unknown as number);

    timeout = setTimeout(later, wait);

    if (callNow) (func as unknown as Function).apply(context, args);
  } as unknown as F;
}

export function throttle<F>(func: F, delay = 500) {
  // Previously called time of the function
  let prev = 0;

  return (...args: any) => {
    // Current called time of the function
    let now = new Date().getTime();

    if (now - prev > delay) {
      prev = now;

      return (func as unknown as Function)(...args);
    }
  };
}

export function getColumnFromIndex(index: number) {
  const base = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  index++;
  let remainder;
  let result = "";
  do {
    remainder = index % 26;
    result = base[(remainder || 26) - 1] + result;
    index = Math.floor(index / 26);
  } while (index > 0);
  return result;
}

export const domToString = (element: Element) => {
  const div = document.createElement("div");
  div.appendChild(element);

  return div.innerHTML;
};

export function exportTableToExcel(table: HTMLTableElement, fileName: string = "") {
  const dataType = "application/vnd.ms-excel";
  const tableHTML = table.outerHTML.replace(/ /g, "%20");

  // Create download link element
  const downloadLink = document.createElement("a");

  document.body.appendChild(downloadLink);

  // Create a link to the file
  downloadLink.href = "data:" + dataType + ", " + tableHTML;

  // Setting the file name
  downloadLink.download = (fileName ? fileName + "_" : "") + (new Date().toDateString() + " " + new Date().toLocaleTimeString().replaceAll(":", "-")) + ".xls";

  //triggering the function
  downloadLink.click();
  downloadLink.remove();
}

export const objToQueryString = (obj: { [k: string]: any }) => {
  const values: string[] = [];

  for (const key in obj) if (obj[key] !== "" && (typeof obj[key] === "string" || typeof obj[key] === "number")) values.push(`${key}=${obj[key]}`);

  return values.join("&");
};

export function formToFormData(form: IFormData) {
  const formData = new FormData();

  for (const k in form) {
    if (Array.isArray(form[k]) || form[k] instanceof FileList) {
      for (const value of form[k] as any[]) formData.append(k, value);
    } else formData.append(k, form[k] as string);
  }

  return formData;
}

export const formatDateTime = (date: any) => {
  if (!date) return "";
  return moment(date).format("DD - MM - YYYY hh:mm A");
};

export function getParameterByName(name: string, url = window.location.href) {
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const tablesToExcel = (function () {
  const uri = "data:application/vnd.ms-excel;base64,";
  const tmplWorkbookXML =
    '<?xml version="1.0"?><?mso-application progid="Excel.Sheet"?><Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet">' +
    '<DocumentProperties xmlns="urn:schemas-microsoft-com:office:office"><Author>Axel Richter</Author><Created>{created}</Created></DocumentProperties>' +
    "<Styles>" +
    '<Style ss:ID="Currency"><NumberFormat ss:Format="Currency"></NumberFormat></Style>' +
    '<Style ss:ID="Date"><NumberFormat ss:Format="Medium Date"></NumberFormat></Style>' +
    "</Styles>" +
    "{worksheets}</Workbook>";

  const tmplWorksheetXML = '<Worksheet ss:Name="{nameWS}"><Table>{rows}</Table></Worksheet>';

  const tmplCellXML = '<Cell{attributeStyleID}{attributeFormula}><Data ss:Type="{nameType}">{data}</Data></Cell>';

  const base64 = function (s: string) {
    return window.btoa(unescape(encodeURIComponent(s)));
  };

  const format = function (s: string, c: any) {
    return s.replace(/{(\w+)}/g, function (m, p) {
      return c[p];
    });
  };

  return function (tables: HTMLTableElement[], sheetNames?: string[], excelFileName?: string) {
    var ctx: string | { created: number; worksheets: string } = "";
    var workbookXML = "";
    var worksheetsXML = "";
    var rowsXML = "";

    for (let i = 0; i < tables.length; i++) {
      // if (!tables[i].nodeType) tables[i] = document.getElementById(tables[i]);
      for (let j = 0; j < tables[i].rows.length; j++) {
        rowsXML += "<Row>";
        for (let k = 0; k < tables[i].rows[j].cells.length; k++) {
          var dataType = tables[i].rows[j].cells[k].getAttribute("data-type");
          var dataStyle = tables[i].rows[j].cells[k].getAttribute("data-style");
          var dataValue = tables[i].rows[j].cells[k].getAttribute("data-value");
          dataValue = dataValue ? dataValue : tables[i].rows[j].cells[k].innerHTML;
          var dataFormula = tables[i].rows[j].cells[k].getAttribute("data-formula");
          dataFormula = dataFormula ? dataFormula : dataType === "DateTime" ? dataValue : null;
          const ctx = {
            attributeStyleID: dataStyle === "Currency" || dataStyle === "Date" ? ' ss:StyleID="' + dataStyle + '"' : "",
            nameType: dataType === "Number" || dataType === "DateTime" || dataType === "Boolean" || dataType === "Error" ? dataType : "String",
            data: dataFormula ? "" : dataValue,
            attributeFormula: dataFormula ? ' ss:Formula="' + dataFormula + '"' : "",
          };
          rowsXML += format(tmplCellXML, ctx);
        }
        rowsXML += "</Row>";
      }
      const ctx = { rows: rowsXML, nameWS: sheetNames?.[i] || "Sheet " + (i + 1) };
      worksheetsXML += format(tmplWorksheetXML, ctx);
      rowsXML = "";
    }

    ctx = { created: new Date().getTime(), worksheets: worksheetsXML };
    workbookXML = format(tmplWorkbookXML, ctx);

    const link = document.createElement("a");
    link.href = uri + base64(workbookXML);
    link.download = excelFileName || "Workbook.xls";
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
})();

// export function getBase64Image(img: HTMLImageElement) {
//   const canvas = document.createElement("canvas");
//   canvas.width = img.width;
//   canvas.height = img.height;

//   const ctx = canvas.getContext("2d");

//   if (ctx) ctx.drawImage(img, 0, 0);

//   const dataURL = canvas.toDataURL("image/jpeg");
//   return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
// }
export function getBase64Image(url: string): Promise<string> {
  return new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      try {
        const reader = new FileReader();
        reader.onloadend = function () {
          res(reader.result as string);
        };
        reader.readAsDataURL(xhr.response);
      } catch (error) {
        rej(null);
      }
    };
    xhr.onerror = () => {
      rej(null);
    };
    xhr.open("GET", url);
    xhr.setRequestHeader("Authorization", `Bearer ${localStorage.getItem("token")}`);
    xhr.responseType = "blob";
    xhr.send();
  });
}
export function rgbToHex(r: number, g: number, b: number) {
  function toHex(a: number) {
    if (a <= 0) return "00";
    else if (a >= 255) return "FF";
    else return a.toString(16).toUpperCase();
  }
  return toHex(r) + toHex(g) + toHex(b);
}
