import React from "react";

export const AllProjectBox = () => (
  <section className="contentBox defaultContainer">
    <div className="container-fluid">
      <header className="contentBox__header">
        <h2 className="">People light our service</h2>

        <p className="subheader">Our projects</p>
      </header>

      <main className="contentBox__content">
        <div className="contentBox__item imageTeaser col2">
          <a href="{}" className="imageTeaser__link">
            <img className="imageTeaser__image" title="L’Oréal, Düsseldorf" alt="L’Oréal, Düsseldorf" src="../fileadmin/Baci202105121620791532.jpg" width="692" height="592" />

            <div className="imageTeaser__textBox">
              <p className="imageTeaser__metaTitle">Projects</p>

              <p className="imageTeaser__title">Bank of America</p>

              <p className="imageTeaser__subTitle">Gandhi Nagar, Gujarat </p>
            </div>
          </a>
        </div>

        <div className="contentBox__item imageTeaser col1">
          <a href="{}" className="imageTeaser__link">
            <figure className="image">
              <picture>
                <source data-variant="default" media="(min-width: 768px)" srcSet="../fileadmin/user_upload/img03.jpg" />
                <source data-variant="extrasmall" data-maxwidth="768" srcSet="../fileadmin/_processed_/d/5/csm_img03_f2d2edf09f.jpg" />
                <img className="imageTeaser__image" src="../fileadmin/user_upload/img03.jpg" width="1400" height="592" alt="Doha, Qatar" title="Doha, Qatar" />
              </picture>
            </figure>

            <div className="imageTeaser__textBox">
              <p className="imageTeaser__metaTitle">Projects</p>

              <p className="imageTeaser__title">Regent lights the Sunlife'</p>

              <p className="imageTeaser__subTitle">Gurgaon, Haryana</p>
            </div>
          </a>
        </div>
      </main>

      <footer className="contentBox__footer">
        <a href="{}" className="btn btn-default">
          All Projects
        </a>
      </footer>
    </div>
  </section>
);
