import axios, { AxiosError, AxiosRequestConfig } from "axios";

import { IFormData, IResponse } from "../configs/types";

export const IMAGE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000/"
    : "https://api.regent-lighting.co.in/";
export const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000/api"
    : "https://api.regent-lighting.co.in/api";
export const EXCEL_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000/"
    : "https://api.regent-lighting.co.in/";

//export const API_URL = process.env.NODE_ENV === "development" ? "https://api.regent-lighting.co.in/api" : "https://api.regent-lighting.co.in/api";

// export const IMAGE_URL = "http://api.regent-lighting.co.in/";
// export const API_URL = "https://api.regent-lighting.co.in/api";
// export const EXCEL_URL = "http://api.regent-lighting.co.in/";

const defaultErrorResponse = {
  error: "Something went wrong! Please try again later.",
};
export const callApi = {
  get: async <T>(url: string): Promise<IResponse<T>> => {
    try {
      const response = await axios.get(API_URL + url);

      return response.data;
    } catch (error) {
      return {
        ...((error as AxiosError).response?.data || defaultErrorResponse),
        statusCode: (error as AxiosError).response?.status || 500,
      };
    }
  },

  post: async <T>(
    url: string,
    data?: IFormData,
    config?: AxiosRequestConfig
  ): Promise<IResponse<T>> => {
    try {
      const response = await axios.post(API_URL + url, data, config);

      return response.data;
    } catch (error) {
      return {
        ...((error as AxiosError).response?.data || defaultErrorResponse),
        statusCode: (error as AxiosError).response?.status || 500,
      };
    }
  },
  put: async <T>(
    url: string,
    data?: IFormData,
    config?: AxiosRequestConfig
  ): Promise<IResponse<T>> => {
    try {
      const response = await axios.put(API_URL + url, data, config);

      return response.data;
    } catch (error) {
      return {
        ...((error as AxiosError).response?.data || defaultErrorResponse),
        statusCode: (error as AxiosError).response?.status || 500,
      };
    }
  },
  patch: async <T>(
    url: string,
    data?: IFormData,
    config?: AxiosRequestConfig
  ): Promise<IResponse<T>> => {
    try {
      const response = await axios.patch(API_URL + url, data, config);

      return response.data;
    } catch (error) {
      return {
        ...((error as AxiosError).response?.data || defaultErrorResponse),
        statusCode: (error as AxiosError).response?.status || 500,
      };
    }
  },
  delete: async <T>(url: string, config?: AxiosRequestConfig): Promise<IResponse<T>> => {
    try {
      const response = await axios.delete(API_URL + url, config);

      return response.data || { message: "Success." };
    } catch (error) {
      return {
        ...((error as AxiosError).response?.data || defaultErrorResponse),
        statusCode: (error as AxiosError).response?.status || 500,
      };
    }
  },
};

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token)
      config.headers = {
        ...(config.headers || {}),
        Authorization: `Bearer ${token}`,
      };
    // Do something before request is sent
    return config;
  },
  (error) => {
    // Do something with request error
    return error.request?.data || { error: "Request Failed." };
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    response.data.statusCode = response.status;
    return response;
  },
  (error) => {
    throw error;
  }
);
