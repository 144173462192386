import React from "react";
const SearchBoxOverlay = () => {
  const onSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <div
      onClick={(e) => {
        document.body.classList.remove("searchFormActive");
      }}
    >
      <div
        className="searchForm__wrapper parent-focus  parent-act
          ive "
      >
        <form onClick={(e) => onSubmit(e)} name="glbal" id="searchForm" className="search searchForm">
          <div className="searchForm__content           ">
            <input type="text" id="Search" className="searchForm__input input-ready focus text-active" name="tx_fdsearch_search[sword]" placeholder="" />
            <button name="search" value="" type="submit" className="icon-search searchForm__submit"></button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchBoxOverlay;
