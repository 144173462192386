import { callApi } from "..";

export const getProductFinderData = async (data: any) => {
  const response = await callApi.post(`/web_product_finder/all`, data);
  return response;
};

export const getProductFinderDataImage = async () => {
  const response = await callApi.get(`/web_product_finder/thumb-nail`);
  return response;
};

export const getProductFinderDataFamilyType = async () => {
  const response = await callApi.get(`/web_product_finder/family-type`);
  return response;
};

export const getProductById = async (id: number | string) => {
  const response = await callApi.get(`/web_product_finder/single-data-sheet/${id}`);
  return response;
};

export const getProductDetails = async (filter: string) => {
  const response = await callApi.get(`/web_product_finder/product-details?${filter}`);
  return response;
};

export const getAllProjects = async () => {
  const response = await callApi.get(`/web_product_finder/projects`);
  return response;
};

export const getProjectByID = async (id: number | string) => {
  const response = await callApi.get(`/web_product_finder/project-details/${id}`);
  return response;
};

export const getAllBespoke = async () => {
  const response = await callApi.get(`/web_product_finder/bespoke`);
  return response;
};

export const getAllProductFamily = async (query: string) => {
  const response = await callApi.get(`/web_product_finder/product-family` + query);
  return response;
};

export const getAllSlider = async () => {
  const response = await callApi.get(`/section/slider`);
  return response;
};
