import React, { Suspense, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/toastr.min.css";

import { IRootState } from "./redux/rootReducer";
import { IUserState } from "./redux/user/userReducer";
import { userActionSignOut } from "./redux/user/userActions";

import { refreshToken } from "./services/users/userServices";
import { EPageLinks } from "./configs/types";
import SiteHome from "./pages/website/site-home";
// import DataSheet from "./pages/website/ProductDetails/DataSheet";
const SignIn = React.lazy(() => import("./pages/sign-in"));

const DataSheet = React.lazy(() => import("./pages/website/ProductDetails/DataSheet"));

const SiteLayout = React.lazy(() => import("./layout/site-layout/index"));
toastr.options.closeButton = true;

function App() {
  const { user } = useSelector<IRootState, { user: IUserState }>((state) => ({ user: state.user }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user.id) return;

    const tokenIssuedAt = localStorage.getItem("tokenIssuedAt");
    const tokenExpiresIn = localStorage.getItem("tokenExpiresIn");
    if (!tokenIssuedAt || !tokenExpiresIn || +tokenIssuedAt + +tokenExpiresIn - Date.now() < 0) {
      dispatch(userActionSignOut());
      return;
    }
  }, [dispatch, user.id]);

  useEffect(() => {
    const listener = async () => {
      if (!localStorage.getItem("token")) return;

      const tokenIssuedAt = localStorage.getItem("tokenIssuedAt") || "";
      const tokenExpiresIn = localStorage.getItem("tokenExpiresIn") || "";

      if (+tokenIssuedAt + +tokenExpiresIn - Date.now() >= 120000) return;

      const response = await refreshToken();

      if (!response.access_token) return;
      localStorage.setItem("token", response.access_token);
      localStorage.setItem("tokenExpiresIn", response.expires_in);
      localStorage.setItem("tokenIssuedAt", Date.now().toString());
    };

    const timer = setInterval(listener, 2000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Suspense fallback={<></>}>
      <Routes>
        {/* check login */}
        <Route path={EPageLinks.SignIn} element={user.id ? <SiteHome /> : <SignIn />} />
        <Route path={"/sheet/:id"} element={<DataSheet />} />
        <Route path={"/*"} element={<SiteLayout />} />
      </Routes>
    </Suspense>
  );
}

export default App;
