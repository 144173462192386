import { callApi } from "..";
import { EUserFieldNames, IUser } from "./userTypes";
import { objToQueryString } from "../../configs/helpers";
import { EApiNames, EFieldInputTypes, IApis, IField, IFormData, IInitialSearchForm, IPage } from "../../configs/types";

export const userFields: IField<EUserFieldNames, unknown, IUser | IInitialSearchForm>[] = [
  {
    name: EUserFieldNames.FirstName,
    title: "First Name",
    filterable: true,
    required: false,
    searchCol: 3,
  },
  {
    name: EUserFieldNames.LastName,
    title: "Last Name",
    filterable: true,
    required: false,
    searchCol: 3,
  },
  {
    name: EUserFieldNames.Username,
    title: "Username",
    filterable: true,
    required: true,
    searchCol: 3,
  },
  // {
  //   name: EUserFieldNames.Roles,
  //   title: "Role",
  //   filterable: true,
  //   required: false,
  //   inputType: EFieldInputTypes.Select,
  //   options: [],
  //   searchCol: 3,
  //   cell: (_value, data) => (data as IUser).roles?.map((r) => r.name),
  // },
  {
    name: EUserFieldNames.Email,
    title: "Email",
    filterable: true,
    required: true,
    inputType: EFieldInputTypes.Email,
    searchCol: 3,
  },
  {
    name: EUserFieldNames.Password,
    title: "Password",
    inputType: EFieldInputTypes.Password,
    required: false,
    hideInTable: true,
    hideInSearchForm: true,
    searchCol: 3,
    hideInEditForm: true,
  },
  {
    name: EUserFieldNames.Phone,
    title: "Mobile Phone",
    inputType: EFieldInputTypes.Number,
    required: true,
    hideInTable: true,
    searchCol: 3,
  },
  {
    name: EUserFieldNames.EmployeeCode,
    title: "Employee Code",
    filterable: true,
    required: false,
    searchCol: 3,
    columnWidth: 160,
  },
];

export const userInitialForm: IUser = Object.freeze({
  [EUserFieldNames.ID]: null,
  [EUserFieldNames.FirstName]: "",
  [EUserFieldNames.LastName]: "",
  [EUserFieldNames.Email]: "",
  [EUserFieldNames.Password]: "",
  [EUserFieldNames.Username]: "",
  [EUserFieldNames.Roles]: [],
  [EUserFieldNames.Phone]: null,
  [EUserFieldNames.EmployeeCode]: "",
});

export const userInitialSearchForm = Object.freeze({
  [EUserFieldNames.FirstName]: "",
  [EUserFieldNames.LastName]: "",
  [EUserFieldNames.Email]: "",
  [EUserFieldNames.Username]: "",
  [EUserFieldNames.EmployeeCode]: "",
});

export const signIn = async (formData: IFormData) => {
  const response = await callApi.post("/sign-in", formData);

  return response;
};

export const signOut = async () => {
  await callApi.post("/sign-out");

  return {
    message: "Signed out.",
  }; // response;
};

export const refreshToken = async () => {
  const response = await callApi.post("/refresh");

  return response;
};

export const createUser = async (formData: IFormData) => {
  const response = await callApi.post<{ id: number }>("/user/store", formData);

  return response;
};
export const updateUser = async (formData: IFormData) => {
  const response = await callApi.put<undefined>(`/user/update/${formData.id}`, formData);

  return response;
};
export const deleteUser = async (formData: IFormData) => {
  const response = await callApi.delete<undefined>(`/user/delete/${formData.id}`);

  return response;
};
export const restoreUser = async (formData: IFormData) => {
  const response = await callApi.patch<undefined>(`/user/restore/${formData.id}`);

  return response;
};

export const getUsers = async (searchData?: IFormData, pagination: IPage = { size: 25, number: 1 }) => {
  const queryString = objToQueryString({ ...(searchData || {}), paginate: "1", perPage: pagination.size, page: pagination.number });
  const response = await callApi.get<IUser[]>(`/user/all?${queryString}`);

  return {
    data: response.responseData?.data || [],
    total: response.responseData?.total || 0,
  };
};

export const userApis: IApis<IUser> = Object.freeze({
  [EApiNames.Create]: {
    api: createUser,
  },
  [EApiNames.Update]: {
    api: updateUser,
  },
  [EApiNames.Delete]: {
    api: deleteUser,
  },
  [EApiNames.Restore]: {
    api: restoreUser,
  },
  [EApiNames.GetAll]: {
    api: getUsers,
  },
});
