import { ChangeEvent, CSSProperties } from "react";

export enum EPageLinks {
  "Home" = "/backend/",
  "SignIn" = "/backend/sign-in",
  "Dashboard" = "/backend/dashboard",
  "ForgotPassword" = "/backend/forgot-password",
  "SignUp" = "/backend/sign-up",
  "Users" = "/backend/users",
  "Roles" = "/backend/roles",
  "Permissions" = "/backend/permissions",
  "StandardProducts" = "/backend/standard-products",
  "NonStandardProducts" = "/backend/non-standard-products",
  "BOQExcel" = "/backend/boq-excel",
  "Projectlist" = "/backend/project/list",
  "BOQComparison" = "/backend/boq-comparison/:id",
  "BOQComparisonCostAndPrice" = "/backend/boq-comparison-cost-price/:id",
  "BOQManagement" = "/backend/boq-management",
  "BOQData" = "/backend/boq-data",
  "ProductBeamAngle" = "/backend/product-beam-angle",
  "ProductBeamDiffuser" = "/backend/product-beam-diffuser",
  "ProductCg" = "/backend/profuct-cg",
  "ProductCe" = "/backend/product-ce",
  "ProductCri" = "/backend/product-cri",
  "ProductCt" = "/backend/product-ct",
  "ProductDia" = "/backend/product-dia",
  "ProductDiffuser" = "/backend/product-diffuser",
  "ProductDirectIndirect" = "/backend/product-direct-indirect",
  "ProductFinish" = "/backend/product-finish",
  "ProductFixture" = "/backend/product-fixture",
  "ProductFlux" = "/backend/product-flux",
  "ProductGroup" = "/backend/product-group",
  "ProductHc" = "/backend/product-hc",
  "ProductHeight" = "/backend/product-height",
  "ProductIk" = "/backend/product-ik",
  "ProductIp" = "/backend/product-ip",
  "ProductKelvin" = "/backend/product-kelvin",
  "ProductLc" = "/backend/profuct-lc",
  "ProductLdc" = "/backend/profuct-ldc",
  "ProductLength" = "/backend/profuct-length",
  "ProductLm" = "/backend/profuct-lm",
  "ProductMaterial" = "/backend/product-material",
  "ProductName" = "/backend/product-name",
  "ProductPhotobiological" = "/backend/product-photobiological",
  "ProductPower" = "/backend/product-power",
  "ProductProfile" = "/backend/product-profile",
  "ProductSdcm" = "/backend/product-sdcm",
  "ProductSensor" = "/backend/product-sensor",
  "ProductShape" = "/backend/product-shape",
  "ProductType" = "/backend/product-type",
  "ProductUgr" = "/backend/product-ugr",
  "ProductAdd" = "/backend/products/add",
  "ProductEdit" = "/backend/products/edit/:productId",
  "NotFound" = "/backend/404",
  "Exception" = "*",
  "Projects" = "/backend/projects",
  "Projects1" = "/backend/project/list",
  "ProjectAdd" = "/backend/project/add",
  "ProjectEdit" = "/backend/project/edit/:projectId",
  "Information" = `/project/edit/information/:projectId`,
  "BOQManagement1" = "/backend/project/edit/boq-management/:projectId",
  "BOQData1" = "/backend/project/edit/boq-data/:projectId/*",
  "PreSpecification" = "/backend/project/edit/pre-specification/:projectId",
  "FinalSpecification" = "/backend/project/edit/final-specification/:projectId",
  "Commercials" = "/backend/project/edit/commercials/:projectId/*",
  "Quotation" = "/backend/project/edit/quotation/:projectId/*",
  "Sampling" = "/backend/project/edit/sampling/:projectId/*",
  "Design" = "/backend/project/edit/design/:projectId/*",
  "Handover" = "/backend/project/edit/handover/:projectId/*",
  "ProjectPlan" = "/backend/project/edit/project-plan/:projectId/*",
  "SupplyChain" = "/backend/project/edit/supply-chain/:projectId/*",
  "ProjectSupport" = "/backend/project/edit/project-support/:projectId/*",
  "Warranty" = "/backend/project/edit/warranty/:projectId/*",
  "Attributes" = "/backend/attributes",
  "AttributesAdd" = "/backend/attributes/add",
  "AttributesEdit" = "/backend/attributes/edit/:attributeId",
  "FormComponents" = "/backend/form-components",
  "FormComponentsAdd" = "/backend/form-components/add",
  "FormComponentsEdit" = "/backend/form-components/edit/:formComponentId",
  "AttributeCategory" = "/backend/attribute-category",
  "FormComponentCategory" = "/backend/form-component-Category",
  "Components" = "/backend/components",
  "ComponentsBill" = "/backend/components-bill",
  "ComponentsBillAdd" = "/backend/components-bill/add",
  "ComponentsBillEdit" = "/backend/components-bill/edit/:componentBillId",
  "ComponentDetails" = "/backend/components/:componentTitle",
  "ProductImageTypeMaster" = "/backend/product-image-type-master",
  "ProductFileTypeMaster" = "/backend/product-file-type-master",
}

export enum EBackendPageRouterLinks {
  "Home" = "/dashboard",
  "SignIn" = "/sign-in",
  "Dashboard" = "/backend/dashboard",
  "ForgotPassword" = "/forgot-password",
  "SignUp" = "/sign-up",
  "Users" = "/users",
  "Roles" = "/roles",
  "Permissions" = "/permissions",
  "StandardProducts" = "/standard-products",
  "NonStandardProducts" = "/non-standard-products",
  "BOQExcel" = "/boq-excel",
  "BOQComparison" = "/boq-comparison/:id",
  "BOQComparisonCostAndPrice" = "/boq-comparison-cost-price/:id",
  "BOQManagement" = "/boq-management",
  "BOQData" = "/boq-data",
  "ProductBeamAngle" = "/product-beam-angle",
  "ProductBeamDiffuser" = "/product-beam-diffuser",
  "ProductCg" = "/profuct-cg",
  "ProductCe" = "/product-ce",
  "ProductCri" = "/product-cri",
  "ProductCt" = "/product-ct",
  "ProductDia" = "/product-dia",
  "ProductDiffuser" = "/product-diffuser",
  "ProductDirectIndirect" = "/product-direct-indirect",
  "ProductFinish" = "/product-finish",
  "ProductFixture" = "/product-fixture",
  "ProductFlux" = "/product-flux",
  "ProductGroup" = "/product-group",
  "ProductHc" = "/product-hc",
  "ProductHeight" = "/product-height",
  "ProductIk" = "/product-ik",
  "ProductIp" = "/product-ip",
  "ProductKelvin" = "/product-kelvin",
  "ProductLc" = "/profuct-lc",
  "ProductLdc" = "/profuct-ldc",
  "ProductLength" = "/profuct-length",
  "ProductLm" = "/profuct-lm",
  "ProductMaterial" = "/product-material",
  "ProductName" = "/product-name",
  "ProductPhotobiological" = "/product-photobiological",
  "ProductPower" = "/product-power",
  "ProductProfile" = "/product-profile",
  "ProductSdcm" = "/product-sdcm",
  "ProductSensor" = "/product-sensor",
  "ProductShape" = "/product-shape",
  "ProductType" = "/product-type",
  "ProductUgr" = "/product-ugr",
  "ProductAdd" = "/products/add",
  "ProductEdit" = "/products/edit/:productId",
  "NotFound" = "/404",
  "Exception" = "*",
  "Projects" = "/projects",
  "Projectlist" = "/project/list",
  "ProjectAdd" = "/project/add",
  "ProjectEdit" = "/project/edit/:projectId",
  "Information" = `/project/edit/information/:projectId`,
  "BOQManagement1" = "/project/edit/boq-management/:projectId",
  "BOQData1" = "/project/edit/boq-data/:projectId/*",
  "PreSpecification" = "/project/edit/pre-specification/:projectId",
  "FinalSpecification" = "/project/edit/final-specification/:projectId",
  "Commercials" = "/project/edit/commercials/:projectId/*",
  "Quotation" = "/project/edit/quotation/:projectId/*",
  "Sampling" = "/project/edit/sampling/:projectId/*",
  "Design" = "/project/edit/design/:projectId/*",
  "Handover" = "/project/edit/handover/:projectId/*",
  "ProjectPlan" = "/project/edit/project-plan/:projectId/*",
  "SupplyChain" = "/project/edit/supply-chain/:projectId/*",
  "ProjectSupport" = "/project/edit/project-support/:projectId/*",
  "Warranty" = "/project/edit/warranty/:projectId/*",
  "Attributes" = "/attributes",
  "AttributesAdd" = "/attributes/add",
  "AttributesEdit" = "/attributes/edit/:attributeId",
  "FormComponents" = "/form-components",
  "FormComponentsAdd" = "/form-components/add",
  "FormComponentsEdit" = "/form-components/edit/:formComponentId",
  "AttributeCategory" = "/attribute-category",
  "FormComponentCategory" = "/form-component-Category",
  "Components" = "/components",
  "ComponentsBill" = "/components-bill",
  "ComponentsBillAdd" = "/components-bill/add",
  "ComponentsBillEdit" = "/components-bill/edit/:componentBillId",
  "ComponentDetails" = "/components/:componentTitle",
  "ProductImageTypeMaster" = "/product-image-type-master",
  "ProductFileTypeMaster" = "/product-file-type-master",
}

export enum EFieldInputTypes {
  "Select" = "select",
  "SelectMultiple" = "select-multiple",
  "Datalist" = "datalist",
  "Checkbox" = "checkbox",
  "Checkboxes" = "checkboxes",
  "Radio" = "radio",
  "Button" = "button",
  "Color" = "color",
  "Date" = "date",
  "DatetimeLocal" = "datetime-local",
  "Email" = "email",
  "File" = "file",
  "Hidden" = "hidden",
  "Image" = "image",
  "Month" = "month",
  "Number" = "number",
  "Password" = "password",
  "Range" = "range",
  "Reset" = "reset",
  "Search" = "search",
  "Submit" = "submit",
  "Tel" = "tel",
  "Text" = "text",
  "Time" = "time",
  "Url" = "url",
  "Week" = "week",
  "TextArea" = "textarea",
  "Custom" = "custom",
}

export interface IOnChangeEvent {
  target: { name: string; value: unknown; type?: EFieldInputTypes; checked?: boolean };
}

export type ExtraToChangeOnChangeForm = (form: IFormData) => { [k: string]: any };

export type OnChangeHandler = (event: IOnChangeEvent | ChangeEvent, extraToChange?: {}) => void;

export interface IInitialForm {
  [k: string]: unknown;
}
export interface IInitialSearchForm {
  [k: string]: unknown;
}

export interface IFieldDependency<N = string> {
  name: N;
  initialValue?: string | number | null;
  viewName?: string;
}

export interface IFieldProps<Name = string, Value = unknown> {
  id?: string;
  name: Name;
  title: string;
  placeholder?: string;
  initialValue?: string | number | null;
  value?: Value;
  multiple?: boolean | ((form: IFormData) => boolean);
  viewValue?: string;
  accept?: string;
  onChange?: OnChangeHandler;
  onChange1?: OnChangeHandler;
  required?: boolean | ((form: IFormData) => boolean);
  setter?: (value: Value, form: IFormData) => any;
  dependencies?: Array<IFieldDependency<Name>>;
  hideInFormOnCondition?: (formData: IFormData) => boolean;
  error?: string;
  readOnly?: boolean | ((formData: IFormData) => boolean);
}

export interface IFieldTextProps<Name = string, Value = unknown> extends IFieldProps<Name, Value> {
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  inputType?: EFieldInputTypes;
}

export interface IFieldSelectProps<Name = string, Value = unknown>
  extends IFieldProps<Name, Value> {
  options?: ISelectOption<Value>[];
  viewName?: string;
  noEmptyOption?: boolean;
}

export interface IField<Name = string, Value = unknown, Data = unknown>
  extends IFieldTextProps<Name, Value>,
    IFieldSelectProps<Name, Value> {
  filterable?: boolean;
  withExportButton?: boolean;
  hideInTable?: boolean;
  hideInForm?: boolean;
  hideInEditForm?: boolean;
  hideInSearchForm?: boolean;
  notSortable?: boolean;
  col?: number | any;
  searchCol?: number;
  columnWidth?: number;
  cell?: (value: Value, data: Data | IInitialForm) => any;
  cellAsImage?: boolean;
  headCellStyle?: CSSProperties;
  renderField?: (arg: IFieldProps<Name, Value>) => any;
  filterInputType?: EFieldInputTypes;
}

export enum EButtonThemes {
  "Light" = "light",
  "Dark" = "dark",
}

export interface IFormData {
  id?: number;
  [k: string]: unknown;
}

export interface IResponse<T = unknown> {
  id?: number;
  error?: string;
  message?: string;
  data?: T;
  responseData?: { [k: string]: any };
  total?: number;
  statusCode?: number;
  [k: string]: any;
}

export interface IPage {
  size: number;
  number: number;
}

export interface IApi<T, D = IFormData> {
  api: (data: D, pagination?: IPage) => Promise<IResponse<T>>;
  body?: IFormData;
}

export enum EApiNames {
  "Create" = "create",
  "Update" = "update",
  "Delete" = "delete",
  "EnableDisable" = "enabled_disable",
  "Restore" = "restore",
  "DeleteBatch" = "deleteBatch",
  "RestoreBatch" = "restoreBatch",
  "Remove" = "remove",
  "Get" = "get",
  "GetAll" = "getAll",
}
export interface IApis<T> {
  [EApiNames.Create]?: IApi<{ id: number }>;
  [EApiNames.Update]?: IApi<unknown>;
  [EApiNames.Delete]?: IApi<unknown>;
  [EApiNames.Restore]?: IApi<unknown>;
  [EApiNames.EnableDisable]?: IApi<unknown>;
  [EApiNames.DeleteBatch]?: IApi<unknown>;
  [EApiNames.RestoreBatch]?: IApi<unknown>;
  [EApiNames.Remove]?: IApi<unknown>;
  [EApiNames.GetAll]?: IApi<T[]>;
  [EApiNames.Get]?: IApi<T>;
}

export interface IAction<T, P = unknown> {
  type: T;
  payload?: P;
}

export interface ISelectOption<V = number | string> {
  boq_name?: string;
  label: string;
  value?: V;
  col?: number | string;
  extraToChange?: {
    [k: string]: any;
  };
  input?: string;
}

export interface IProductObj {
  [k: string]: any;
}
