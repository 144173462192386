import React from "react";

function Jobs() {
  return (
    <section className="fullscreenTeaser maxContainer " style={{ backgroundImage: `url("/fileadmin/user_upload/img06.jpg")` }}>
      <div className="container-fluid">
        <div className="fullscreenTeaser__content">
          <header className="fullscreenTeaser__header">
            <p className="fullscreenTeaser__meta">Jobs &amp; Career</p>

            <h2 className=" fullscreenTeaser__title">People light our way</h2>

            <p className="fullscreenTeaser__subheader subheader">Work at Regent Lighting</p>
          </header>

          <main className="fullscreenTeaser__text">
            <p>
              People are our drive and our foundation. Our employees make Regent Lighting what it is and shape our open corporate culture. With us, everyone has the opportunity to contribute their
              ideas and share inspiration. Communication on a personal level and an appreciative approach form the basis of our work together. Our wide range of further training options offer
              interesting perspectives and promote agile working methods. We all share a motivated and committed outlook, a passion for light and for creating exceptional atmospheres through
              high-quality lighting.
            </p>
            <p>
              <a href="{}" className="btn btn-transparent">
                Jobs &amp; Career
              </a>
            </p>
          </main>
        </div>
      </div>
    </section>
  );
}

export default Jobs;
