import React from "react";

export const HeaderContentBox = () => (
  <section className="contentBox defaultContainer">
    <div className="container-fluid">
      <header className="contentBox__header">
        <h1 className="">People light our way</h1>

        <p className="subheader">People. That is why we’re here.</p>
      </header>

      <main className="contentBox__content">
        <div className="contentBox__item richtext text__column col1">
          <p>
            The people who founded our company did so to light the lives of the people of their time. Make those lives brighter and better. This is what we did then, what we do now and what we will
            always do. Every innovation, every design, every product is born out of a restless quest to improve and enhance the work and living spaces … of people. Their environment is our world. So,
            we will discover and develop new technologies, generate new ideas, pursue everything which can improve those environments. And people will continue to power our days. The people who plan,
            the people who install, the people who ultimately benefit from the optimised atmosphere our lights and luminaires create. And, of course, the people who are Regent Lighting. If there is
            something which will illuminate and inspire, we will find it. And people will light our way.
          </p>
        </div>
      </main>
    </div>
  </section>
);
